import { Box, ClickAwayListener, Table, TableBody, TableCell, TableRow, IconButton, Drawer, Tooltip, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch } from '../store';
import { calculatePrice, isChildOf, appendSectionPrice, appendOptional, getViewIdSuffix, getOnlySelectedSections, sanitizeSummarySection, isScope, isSubModel, isSubModelAssigned, getSubModelsFromSectionId, getFormattedPrice, getSymbol, removeViewSuffix, showConfigurationSummaryTabDrawer } from '../services';
import { ConfigurationSummaryPanel } from './ConfigurationSummaryPanel';
import { ISectionPrice, IConfigurationVariablePrice, IApplicationSettings, IExtendedConfigureResponse, IFeatureFlags } from '../../types';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import { DetailedSummaryDialog } from './DetailedSummaryDialog';
import { ExportConfigurationSummary } from './ExportConfigurationSummary';
import { isFeatureVisible } from '../services/ClaimsHelper';
import { isValidCurrency } from '../services/Price';
import DraggableComponent from './DraggableComponent';
import { setApplicationSettings } from '../store/states/ApplicationSettingsSlice';
import { SaveAndClose } from './SaveAndClose';
import { IsDefaultFlowInSessionStore } from '../services/SessionStoreHelperFunctions';
export const defaultDrawerWidth = 350;
/**
 * Returns scope section from the sections of given section
 * @param {ISectionPrice} section the root section 
 * @returns {ISectionPrice} the scope section
 */
export function getScopeSection(section: ISectionPrice) {
  return section.sections.find(isScope);
}

function onToggleInfoScreen(applicationSettings: IApplicationSettings, dispatch: AppDispatch) {
  if (applicationSettings.pageSize.isMedium) {
    dispatch(setApplicationSettings({
      forceShowSummary: !applicationSettings.forceShowSummary,
      forceShowSectionTabs: false
    }));
  } else {
    dispatch(setApplicationSettings({ showSummary: !applicationSettings.showSummary }));
  }
}

const renderExportConfiguration = (exportType: string, featureFlags: IFeatureFlags) => {
  return isFeatureVisible(featureFlags.ExportConfiguration) ? <ExportConfigurationSummary type={exportType} /> : null;
}

const renderDetailedConfiguration = (featureFlags: IFeatureFlags) => {
  return isFeatureVisible(featureFlags.DetailedConfiguration);
}

export const renderListPrice = (featureFlags: IFeatureFlags) => {
  return isFeatureVisible(featureFlags.ListPrice);
}

function isModelSection(id: string) {
  return id.endsWith(getViewIdSuffix());
}

const isModelSectionPrice = (sec, configuration, detailed) => {
  if (isModelSection(sec.id)) {
    const submodels = getSubModelsFromSectionId(sec.id);
    if (isSubModelAssigned(configuration, submodels)) {
      sec.productId = removeViewSuffix(sec.id);
      sec.basePrice = configuration.price?.[sec.productId] ? configuration.price[sec.productId].price : undefined;
      detailed.sections[detailed.sections.findIndex(detSec => sec.id === detSec.id)].basePrice = sec.basePrice;
    }
  }
}

// addBasePrice to Model & SubModel if basePrice available
function addBasePriceToSection(rootSection: ISectionPrice, configuration: IExtendedConfigureResponse, detailed: { id?: string; children: any; sections: any[]; name?: string; price?: number; basePrice?: number; }) {
  if (rootSection.id && isModelSection(rootSection.id)) {
    rootSection.basePrice = configuration.price?.[rootSection.productId] ? configuration.price[rootSection.productId].price : undefined;
    detailed.basePrice = rootSection.basePrice;
    //traverse each section of root to assign basePrice to submodel if isSubModelAssigned
    rootSection.sections.forEach((sec, secIndex) => {
      isModelSectionPrice(sec, configuration, detailed)
    })
  }
}

const applicationSettingsData = (applicationSettings, dispatch) => {
  if (applicationSettings.pageSize.isMedium) {
    dispatch(setApplicationSettings({
      forceShowSummary: !applicationSettings.forceShowSummary,
      forceShowSectionTabs: false,
    }));
  }
}

const renderCurrencySymbol = (currency) => {
  return <span className={!isValidCurrency(currency.currencyCode) ? 'invalidCurrency' : 'priceTitle'}>
    {currency.currencyCode &&
      getSymbol(currency.countryCode, currency.currencyCode)}
  </span>
}

//This function set the drawer width to the state
function updateDrawerWidth( offsetRight:number, minWidth:number, maxWidth:number, setDrawerWidth: { ( value: React.SetStateAction<number> ): void; } ) {
  if ( offsetRight > minWidth && offsetRight < maxWidth ) {
    setDrawerWidth( offsetRight );
  }
}

/**
 * Renders the information container at the right side of the home screen.
 * On smaller screens it will be hidden automatically
 * @returns {JSX.Element} the information container component
 */
export const InfoContainer = () => {
  const dispatch = useDispatch<AppDispatch>();

  //selector
  const claimsData = useSelector((state: AppState) => state.claimsData)
  const applicationSettings = useSelector((state: AppState) => state.applicationSettings)
  const configuration = structuredClone(useSelector((state: AppState) => state.configuration))

  const onClickAway = (e: MouseEvent | TouchEvent) => !isChildOf(e.target as HTMLElement, 'summaryScreenToggle') &&
    applicationSettings.forceShowSummary &&
    dispatch(setApplicationSettings({ forceShowSummary: false }));

  const show = showConfigurationSummaryTabDrawer(applicationSettings);
  const { t } = useTranslation();


  const rootSections = configuration.data?.sections;
  const sections = getOnlySelectedSections(rootSections);
  const currency = applicationSettings.currency;
  const rootSection = {
    id: `${configuration.savedConfiguration?.modelContext.rootModel.id}${getViewIdSuffix()}`,
    name: configuration.savedConfiguration?.modelContext.rootModel.name,
    sections: [...sections] as ISectionPrice[],
    variables: [] as IConfigurationVariablePrice[],
    price: 0,
    isComplete: configuration.data?.isConfigComplete,
    productId: configuration.savedConfiguration?.modelContext.rootModel.id
  } as ISectionPrice;
  appendSectionPrice(rootSection);
  appendOptional(rootSection);
  sanitizeSummarySection(rootSection);
  const scope = getScopeSection({ ...rootSection });
  rootSection.sections = rootSection.sections.filter((s) => !isScope(s));

  const [drawerWidth, setDrawerWidth] = React.useState<number>( defaultDrawerWidth );
  const [userSelect, setUserSelect] = React.useState(false);
  const handleMouseDown = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };
  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
    setUserSelect(false);
  };
  const handleMouseMove = useCallback((e: { clientX: number; }) => {
    setUserSelect(true);
    const offsetRight =
      document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    const minWidth = 320;
    const maxWidth = 640;
    updateDrawerWidth( offsetRight,minWidth,maxWidth,setDrawerWidth );
    
  }, []);
  const data = { sx: { position: 'relative', top: 'auto', width: drawerWidth, background: '#eee' } };
  if (applicationSettings.pageSize.isMedium) {
    data.sx.position = 'absolute';
  }
  function setDocumentStyle(value: string) {
    document.body.style.userSelect = value
  }
  { userSelect ? setDocumentStyle('none') : setDocumentStyle('') }


  const [showDialog, setShowDialog] = useState(false);
  const onClose = (_event?: any, reason?: any) => {
    if (reason !== 'backdropClick') {
      setShowDialog(false);
    }
  }
  const title = t('configurationSummary.title');
  const detailed = { id: rootSection.id, productId: rootSection.productId, children: [], sections: sections, name: rootSection.name, price: rootSection.price };
  addBasePriceToSection(rootSection, configuration, detailed)

  return <>{!show ? <div className="tabDrawerMini">
    <IconButton id="tabDrawerToggle" onClick={() => onToggleInfoScreen(applicationSettings, dispatch)} >  <KeyboardDoubleArrowLeftIcon className="pinIcon" /></IconButton>
    <span className="navigateTabLabel rotate0" onClick={() => onToggleInfoScreen(applicationSettings, dispatch)}>{title}</span>
  </div> :
    <><ClickAwayListener onClickAway={onClickAway}>
      <Drawer
        anchor={'right'}
        variant="permanent"
        PaperProps={data}
        className={classNames('drawer-class', { ['hover']: applicationSettings.pageSize.isMedium })}
      >
        <div
          onMouseDown={(e) => handleMouseDown(e)}
          className="dragger"
          data-testid="dragger"
        />
        <div />
        <div className='summary-view'>
          <div className=
            {classNames({ ['disabled']: !show, ['infoPanelStyle root']: !applicationSettings.pageSize.isMedium, ['summaryViewHeight']: !applicationSettings.pageSize.isMedium && !IsDefaultFlowInSessionStore(), ['infoPanelStyle rootNew']: applicationSettings.pageSize.isMedium, ['extraSmallHeaderHeight']: applicationSettings.pageSize.isExtraSmall, ['headerHeight']: !applicationSettings.pageSize.isExtraSmall })}
          >

            <IconButton className="box-iconbtn" data-testid="box-iconbtn" onClick={() => onToggleInfoScreen(applicationSettings, dispatch)}>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
            {renderExportConfiguration('quick', claimsData.featureFlags)}
            {renderDetailedConfiguration(claimsData.featureFlags) && <Tooltip title={t('labels.expand')} >
              <IconButton className="box-openInbtn" data-testid="box-openInbtn" onClick={() => {
                setShowDialog(true);
                applicationSettingsData(applicationSettings, dispatch)
              }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>}
            <h5 className={classNames('title', { ['disabledContent']: !show, ['content']: show })} >
              {t('configurationSummary.title')}
            </h5>
            {renderListPrice(claimsData.featureFlags) && <Box className="rootPrice">
              <Table className="box-table" size="small" aria-label="Configuration Summary Table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <span className="priceTitle">
                        {t('labels.total')}
                      </span>
                    </TableCell>
                    <TableCell className="box-tablecell" align="right">
                      {renderCurrencySymbol(currency)}
                      <span className="priceTitle">
                        {currency.currencyCode &&
                          getFormattedPrice(calculatePrice(rootSection, configuration))}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>}
            <ConfigurationSummaryPanel rootSection={rootSection} scope={scope} />
          </div>
          {!IsDefaultFlowInSessionStore() && <div className='add-product-btn'>
            <SaveAndClose configuration={configuration} />
          </div>}
        </div></Drawer>
    </ClickAwayListener> </>
  }
    {showDialog && <> <Dialog className="common-dialog-style detailedSummaryDialogStyle cursor-move" open={true} onClose={onClose} PaperProps={{ className: 'paper' }} PaperComponent={DraggableComponent} aria-labelledby="draggable-dialog-title" >
      <DialogTitle className="header title" >
        <div className="detailedSummaryDialogTitle">{t('detailedConfigurationSummary.title')}</div>
      </DialogTitle>
      <DialogContent className="check">
        {renderExportConfiguration('detailed', claimsData.featureFlags)}
        <IconButton className="closeIcon" data-testid="closeIcon" aria-label="close" onClick={onClose} >
          <CloseIcon />
        </IconButton>
        <DetailedSummaryDialog detailedSummaryData={detailedConfigurationSummary(sections, detailed, configuration)} />
      </DialogContent>
    </Dialog> </>
      || null}
  </>
}

const detailedConfigurationSummary = (sections: ISectionPrice[], detailed: { id: string; children: any; sections: any[]; name: string; price: number; }, configuration: IExtendedConfigureResponse) => {
  sections.forEach(sec => {
    sec.variables = sec.optional ? [...sec.optional.variables, ...sec.variables] : sec.variables;
    const submodels = getSubModelsFromSectionId(sec.id);
    if (isSubModelAssigned(configuration, submodels)) {
      if (isSubModel(sec)) {
        detailed.children.push({ id: sec.id, productId: sec.productId ?? removeViewSuffix(sec.id), children: [], sections: sec.sections, name: sec.name, price: sec.price });
        if (sec.basePrice) {
          detailed.children[detailed.children.length - 1].basePrice = sec.basePrice;
        }
      }
      if (sec.sections.length > 0) {
        sec.id.split('.').pop();
        const resultantChildren = detailed.children.find((child: { id: string; }) => child.id === sec.id)
        detailedConfigurationSummary(sec.sections, resultantChildren, configuration)
      }
    }
  })
  return detailed;
}