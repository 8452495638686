import React, { useEffect, useState } from 'react';
import { AppDispatch, AppState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import { useAuth } from 'oidc-react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useTranslation } from 'react-i18next';
import { DefaultFlow, EFilterRootOptions, ESessionStore, ETabValue } from '../data/Constants';
import { Unauthenticated, UnsupportedBrowser } from '.';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { IHierarchy, IHierarchyBusinessUnits, IGetOnChangeProps, IFilterCodeAndValue, IArrayOfFilterElementsCodes } from '../../types';
import { SessionStore } from '../services/SessionStore';
import { appSettings } from '../settings';
import { ProductPortfolio } from './ProductPortfolio';
import { MyConfigurations } from './MyConfigurations';
import { getMyConfigurations, getProductCatalog, updateMyConfigPageDetails, updateProductCatalogDetails } from '../store/states/ProductSettingsSlice';
import { changeLandingTab, updateProductSearchValue } from '../store/states/ConfigurationSlice';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import MultiValueShowControl from './InputComponents/MultiValueShowControl';

function getFilterOtionWhenParrentNodeisNotNull(selectedFilter, parentNode, array) {
// Initialize the available filtered value to the below variable to avoid to data loss or duplication.
let channelWithCode: string[] = [...selectedFilter.channelCodes];
let channelWithNameOrDescription: string[] = [...selectedFilter.channelNames];
let businessOrApplicationWithCode: string[] = [...selectedFilter.applicationIds];
let businessOrApplicationWithNameOrDesctiption: string[] = [...selectedFilter.applicationNames];

// Below check takes value form parentNode, parent node contains the selected filter item only
  // Below check segrigate the selected option based on the group
  // It will be use to show the selected filter in collapased view and sending the request with separate filter group
  if (parentNode?.code === EFilterRootOptions.Channel) {
    channelWithCode = [];
    channelWithNameOrDescription = [];
    parentNode.values.forEach((node) => {
      if (array.includes(node.code)) {
        channelWithCode.push(node.code);
        const name = getName(node);
        channelWithNameOrDescription.push(name);
      }
    })
  }

  if (parentNode?.code === EFilterRootOptions.Business || parentNode?.code === EFilterRootOptions.Application) {
    businessOrApplicationWithCode = [];
    businessOrApplicationWithNameOrDesctiption = [];
    parentNode.values.forEach((node) => {
      if (array.includes(node.code)) {
        const name = getName(node);
        const articleGroup = getArticleGroup(node);
        businessOrApplicationWithCode.push(node.code);        
        businessOrApplicationWithCode = [...businessOrApplicationWithCode, ...articleGroup];        
        businessOrApplicationWithNameOrDesctiption.push(name);
      }
    })
  }

  // Preparing the all the selected filter options to update the state
  return  {
    applicationIds: businessOrApplicationWithCode,
    channelCodes: channelWithCode,
    applicationNames: businessOrApplicationWithNameOrDesctiption,
    channelNames: channelWithNameOrDescription
  }
}

function getFilterOtionWhenParrentNodeisNull(selectedFilter, array, nodes) {
  // Initialize the available filtered value to the below variable to avoid to data loss or duplication.
  let channelWithCode: string[] = [...selectedFilter.channelCodes];
  let channelWithNameOrDescription: string[] = [...selectedFilter.channelNames];
  let businessOrApplicationWithCode: string[] = [...selectedFilter.applicationIds];
  let businessOrApplicationWithNameOrDesctiption: string[] = [...selectedFilter.applicationNames];
  
    // Below check takes value form nodes which contains all the element when root element is selected and parent element is null.
    // Below check perform when user selct the root element of the filter.
    // In the case of root element selection parent element is null.
    if (nodes.code === EFilterRootOptions.Channel) {
      channelWithCode = [];
      channelWithNameOrDescription = [];
      if (array.includes(nodes.code)) {
        nodes.values.forEach((node) => {
          const name = getName(node);
          channelWithCode.push(node.code);
          channelWithNameOrDescription.push(name);
        })
      }
    }
  
    if (nodes.code === EFilterRootOptions.Business || nodes.code === EFilterRootOptions.Application) {
      businessOrApplicationWithCode = [];
      businessOrApplicationWithNameOrDesctiption = [];
      if (array.includes(nodes.code)) {
        nodes?.values.forEach((node) => {
          const name = getName(node);
          const articleGroup = getArticleGroup(node);
          businessOrApplicationWithCode.push(node.code);
          businessOrApplicationWithCode = [...businessOrApplicationWithCode, ...articleGroup];        
          businessOrApplicationWithNameOrDesctiption.push(name);
        })
      }
    }
  
    // Preparing the all the selected filter options to update the state
    return {
      applicationIds: businessOrApplicationWithCode,
      channelCodes: channelWithCode,
      applicationNames: businessOrApplicationWithNameOrDesctiption,
      channelNames: channelWithNameOrDescription
    }
  
  }

  function getName(node) {
    return node?.description ? node.description : node.name;
  }

  function getArticleGroup(node) {
    return node?.articleGroups ? node.articleGroups : [];
  }

function selectCategory(checked: boolean, props: IGetOnChangeProps): [IFilterCodeAndValue, IArrayOfFilterElementsCodes] {
  const { nodes, selected, parentNode, tabCategory, selectedChannel, selectedFilter } = props
  const allNode: string[] = [nodes.code, ...getAllChild(nodes, parentNode)];
  let selectedFilterData: IFilterCodeAndValue | null = null;
  let array: IArrayOfFilterElementsCodes = [];
  if (tabCategory === ETabValue.Product) {
    array = checked
      ? [...selected, ...allNode]
      : selected.filter(value => !allNode.includes(value));
  } else {
    array = checked
      ? [...selectedChannel, ...allNode]
      : selectedChannel.filter(value => !allNode.includes(value));
  }

  array = array.filter((v, i) => array.indexOf(v) === i);

  if (parentNode) {
    const collectChildIds = getAllChild(parentNode);
    const parentCheck = collectChildIds.every(val => array.includes(val));
    if (parentCheck) {
      array.push(parentNode.code)
    } else {
      if (array.indexOf(parentNode.code) !== -1) {
        array.splice(array.indexOf(parentNode.code), 1)
      }
    }
  }

  if(parentNode === null) {
    selectedFilterData = getFilterOtionWhenParrentNodeisNull(selectedFilter, array, nodes);
  } else {
    selectedFilterData = getFilterOtionWhenParrentNodeisNotNull(selectedFilter, parentNode, array);
  }
  

  // Return the data to immidiatly sent into the request payload
  return [selectedFilterData, array];
}

// gets All Child Codes if parentNode is checked   
function getAllChild(nodes: IHierarchy, parentNode?: IHierarchy) {
  let array: string[] = [];
  if (nodes === null) {
    return [];
  }

  if (Array.isArray(nodes.values)) {
    nodes.values.forEach((node: IHierarchyBusinessUnits) => {
      array.push(node.code);
      array = array.concat(node.code);
      if(node.articleGroups) {
        array = array.concat(node.articleGroups);
      }      
    });
  }
  
  // This is hadled because of parrent node only come when chield node get's selected.
  if (parentNode) {
    array = nodes.articleGroups ? array.concat(nodes.articleGroups) : array.concat(nodes.code);
  }
  return array;
}

async function getOnChange(checked: boolean, props: IGetOnChangeProps) {
  const { configuration, setSelected, tabCategory, setSelectedChannel, token, productSettings, dispatch, setSelectedFilter } = props
  if (tabCategory === ETabValue.Product) {
    // Here selected option constains segrigated value that need to pass in the request.
    // Here array contains all the selected items value, which reflects the on the view. 
    const [selectedOption, array] = selectCategory(checked, props);

    setSelected(array);
    // Set the state  to show the data on parent/root element toggle.
    setSelectedFilter(selectedOption);

    await dispatch(updateProductCatalogDetails({ page: 1, limit: productSettings.productCatalogDetails.limit, totalRecords: productSettings.productCatalogDetails.totalRecords, agCodes: selectedOption.applicationIds, channelCodes: selectedOption.channelCodes }));
    await dispatch(getProductCatalog({ token: token, page: 1, limit: productSettings.productCatalogDetails.limit, productSearch: configuration.productSearchValue, agCodes: selectedOption.applicationIds, channelCodes: selectedOption.channelCodes }));

  } else {
    // Here selected option constains segrigated value that need to pass in the request.
    // Here array contains all the selected items value, which reflects the on the view.
    const [selectedOption, array] = selectCategory(checked, props);
    setSelectedChannel(array);
    // Set the state  to show the data on parent/root element toggle.
    setSelectedFilter(selectedOption);
    await dispatch(updateMyConfigPageDetails({ page: 1, limit: productSettings.myConfigPageDetails.limit, totalRecords: productSettings.myConfigPageDetails.totalRecords, applicationIds: selectedOption.applicationIds, channelCodes: selectedOption.channelCodes }));
    await dispatch(getMyConfigurations({ token: token, page: 1, limit: productSettings.myConfigPageDetails.limit, productSearch: configuration.productSearchValue, applicationIds: selectedOption.applicationIds, channelCodes: selectedOption.channelCodes }));
  }
}

const traverseInnerNodes = (tabCategory, nodes, renderTreeProps, dispatch: AppDispatch) => {
  if (Array.isArray(nodes.values)) {
    return nodes.values.map((node: IHierarchyBusinessUnits) => renderTree(node, renderTreeProps, tabCategory, dispatch, nodes))
  } else {
    return null
  }
}


const selectUnSelect = (channel, nodes): boolean => {
  return channel.some(item => item === nodes?.code);
}


const renderTree = (nodes: IHierarchy, renderTreeProps, tabCategory: string, dispatch: AppDispatch, parentNode: IHierarchy | null = null, expanded?: string[], showClerfilter?: boolean) => {
  const { selected, selectedChannel, configuration, setSelected, setSelectedChannel, token, productSettings, selectedFilter, setSelectedFilter } = renderTreeProps;
  const channel = tabCategory === ETabValue.Product ? selected : selectedChannel;
  let checked = selectUnSelect(channel, nodes);
  let showSelectedFilterInCollapsed: string[] = [];
  const onChangeEvent = async (event) => {
    const rootNodeChecked: boolean = event.currentTarget.checked != undefined ? event.currentTarget.checked : false;
    await getOnChange(rootNodeChecked, { nodes, configuration, selected, setSelected, parentNode, tabCategory, selectedChannel, setSelectedChannel, token, productSettings, dispatch, selectedFilter, setSelectedFilter });
  }

  // This function set the value to show in tree view collapsed mode
  // It checks the root/parent element type and assign the corresponding value.
  // It return true and false based on the value availabe in the expanded varialbe.
  const showNodeIsCollapsed = (node: string): boolean => {
    if (node === EFilterRootOptions.Channel) {
      showSelectedFilterInCollapsed = selectedFilter.channelNames;
    } else if (node === EFilterRootOptions.Business || node == EFilterRootOptions.Application) {
      showSelectedFilterInCollapsed = selectedFilter.applicationNames;
    } else {
      showSelectedFilterInCollapsed = [];
    }
    // If the parent/root element value is available in the expanded varialbe it means it is expanded.
    return !expanded?.includes(node) && nodes.code == node;
  }

  return <><TreeItem
    key={nodes.code}
    itemId={nodes.code}
    className="filter-container"
    data-testid="filter-container"
    label={
      <FormControlLabel
        control={
          <>
            <Checkbox
              checked={checked}
              onChange={event => {
                onChangeEvent(event)
              }}
              id="filter-checkbox"
              className="filter-checkbox"
              data-testid="filter-checkbox" />
            {/* Show the clear filter icon with parent/root elemnt when showClerfilter variable is true  */}
            {showClerfilter ? <IconButton aria-label="filter" onClick={(event) => { onChangeEvent(event) }} className='filter-button'>  <FilterAltOffIcon /></IconButton> : ''}

          </>
        }
        label={tabCategory === ETabValue.Product ? <>{nodes.description}</> : <>{nodes.name}</>}
        key={nodes.code}
        className="filter-label"
      />
    }
  >
    {traverseInnerNodes(tabCategory, nodes, renderTreeProps, dispatch)}
  </TreeItem>
    {/* Show the selected filter item in collapsed mode when filter items has selected */}
    {(showNodeIsCollapsed(EFilterRootOptions.Channel) || showNodeIsCollapsed(EFilterRootOptions.Business) || showNodeIsCollapsed(EFilterRootOptions.Application)) && channel.length > 0 &&
      <>
        {showSelectedFilterInCollapsed.length > 0 && <MultiValueShowControl options={showSelectedFilterInCollapsed} />}
      </>
    }
  </>
};

const renderProductHierarchy = (prodHierarchy: IHierarchy[], treeProps, t: (arg: string, arg2?: object) => string, tabCategory: string, dispatch: AppDispatch, expanded, showClerfilter: boolean) => {
  return prodHierarchy.length < 1 ? <span className="noDataAvailable-label" >{t('landingPage.noDataAvailable')}</span> : prodHierarchy.map(val => renderTree(val, treeProps, tabCategory, dispatch, null, expanded, showClerfilter))
}

const tabChangeWithSearchValue = (requestPayload: any, dispatch: AppDispatch) => {
  const { tabValue, configuration, token, currentTabValue, limit, applicationIds, channelCodes,} = requestPayload;
  if (tabValue === currentTabValue) {
    if (configuration.productSearchValue) {
      if (tabValue == ETabValue.MyConfiguration) {
        return dispatch(getMyConfigurations({ token: token, page: 1, limit: limit, productSearch: '', applicationIds: applicationIds, channelCodes: channelCodes }))
      } else if (tabValue == ETabValue.Product) {
        return dispatch(getProductCatalog({ token: token, page: 1, limit: limit, productSearch: '', agCodes: applicationIds, channelCodes: channelCodes }))
      }
    } else {
      return null
    }
  }
}

export const LandingPage = () => {
  const auth = useAuth();
  const token = auth.userData?.access_token || '';
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  //selectors
  const productSettings = useSelector((state: AppState) => state.productSettings);
  const configuration = useSelector((state: AppState) => state.configuration)

  if (!configuration) {
    return null;
  }

  const [prodHierarchy, setProdHierarchy] = useState<IHierarchy[]>([]);
  const [tabValue, setTabValue] = useState<string | null>(ETabValue.Product);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1450);

  SessionStore.set(ESessionStore.Flow, DefaultFlow);
  SessionStore.set(ESessionStore.Scope, appSettings.ApiKey);


  const handleTabChange = (...params: [React.BaseSyntheticEvent, string]) => {
    setTabValue(params[1]);
    dispatch(updateProductSearchValue({ productSearch: '' }));
    dispatch(changeLandingTab({ value: true, tabValue: tabValue === ETabValue.Product ? ETabValue.MyConfiguration : ETabValue.Product }));
    const commonPayload = {
      tabValue: tabValue,
      configuration: configuration,
      token: token,
    }
    const myConfigurationData = {
      ...commonPayload,
      currentTabValue: ETabValue.MyConfiguration,
      limit: productSettings.myConfigPageDetails.limit,
      applicationIds: productSettings.myConfigPageDetails.applicationIds,
      channelCodes: productSettings.myConfigPageDetails.channelCodes,
    }
    const propductPortFolioData = {
      ...commonPayload,
      currentTabValue: ETabValue.Product,
      limit: productSettings.productCatalogDetails.limit,
      applicationIds: productSettings.productCatalogDetails.agCodes,
      channelCodes: productSettings.productCatalogDetails.channelCodes,
    }
    tabChangeWithSearchValue(myConfigurationData, dispatch);
    tabChangeWithSearchValue(propductPortFolioData, dispatch);
  };

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 1450);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  if (!auth || !auth.userData) {
    return <Unauthenticated />;
  }


  return <Box className="landing-Page">
    <TabContext value={tabValue || ETabValue.Product}>

      <Box className="dialog-box">
        <TabList onChange={handleTabChange} >
          <Tab className="dialog-tab text-capitalize" data-testid="product-portfolio"
            value={ETabValue.Product} label={t('landingPage.productPortfolio')}
          />
          <Tab className="dialog-tab text-capitalize" data-testid="my-configuration"
            value={ETabValue.MyConfiguration} label={t('landingPage.myConfigurations')}
          />
        </TabList>
      </Box>

      <TabPanel value={ETabValue.Product} className="product-portfolio" >
        <ProductPortfolio prodHierarchy={prodHierarchy} setProdHierarchy={setProdHierarchy} renderProductHierarchy={renderProductHierarchy} />
      </TabPanel>

      <TabPanel className="myConfiguration-tab" value={ETabValue.MyConfiguration} >
        <MyConfigurations isDesktop={isDesktop} renderProductHierarchy={renderProductHierarchy} />
      </TabPanel>

    </TabContext>
    <UnsupportedBrowser />
  </Box>
}
