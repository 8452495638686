import { AxiosError } from 'axios';
import { getPackagePath, getProductId } from '../services';
import { t } from 'i18next';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';


export const SalesTextApi = {
  salesText: ( action: { language: string, token: string, modelId: string, apiType: string, showError?: boolean } ) => {
    const productModel = action.modelId ? action.modelId : getProductId();
    return AxiosClient
      .get( `/product/v1/salestext/read?packagePath=${getPackagePath( getProductId() )}&productModel=${productModel}&type=${action.apiType}`,
      getHeaders( action.token, action.language )
      ).then( ( response ) => {
        return {
          salesText: response.data,
          apiType: action.apiType,
          modelId: productModel
        }
      } ).catch( ( err: AxiosError ) => {
        if ( action.showError ) {
          return {
            error: {
              code: err.response?.status,
              message: t( 'salesText.notFound' )
            }
          }
        }
        return {
          apiType: action.apiType,
          modelId: productModel
        }
      } )
  }
}