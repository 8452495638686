import { appSettings } from "../settings";

export const requestHeader: any = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'Philips_Ace_Configurator',
};

//Get the api headers 
export const getHeaders = ( token: string, lang?:string ) => {
  let headerValue;
  if ( lang ) {
    headerValue =  { 'Authorization': `Bearer ${token}`, 'Accept-Language':lang }
  } else {
    headerValue ={ 'Authorization': `Bearer ${token}` }
  }
  return !appSettings.STAuth ? { headers: headerValue } : undefined
}